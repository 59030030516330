import React, { Fragment } from "react"
import Layout from "../components/layout"
import HomeBanner from "../components/Home/HomeBanner"
import HomeContent from "../components/Home/HomeContent"
import { Helmet } from "react-helmet"
import "./index.css"

const IndexPage = () => (
  <>
    <Helmet>
      <title>Maxie Paper</title>
      <meta charSet="utf-8" />
      <meta name="author" content="Melissa Wong"/>
      <meta name="description" content=""/>
      <meta name="keywords" content="Maxie, Maxie Needs, Toilet Paper"/>
    </Helmet>
    <Layout>
      <HomeBanner/>
      <HomeContent/>
    </Layout>
  </>
)

export default IndexPage